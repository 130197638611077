
import Api from '@/services/Index';
import i18n from '@/plugins/i18n';

const create = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    formData.language = i18n.locale.toUpperCase()
    return Api.post('/student/documents/create', formData);
}

const downloadCourseSchedule = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/course-schedule/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadDispatchPostponement = async (id, lang, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = {
        language: lang.toUpper(),
        ...data
    }
    return Api.post('/student/documents/dispatch-postponement/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadExamSchedule = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/exam-schedule/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadSchoolCertificate = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/school-certificate/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadLanguageProficiency = async (id, lang, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper()};
    if (note) {
        formData.note = note;
    }
    return Api.post('/student/documents/language-proficiency/' + id, formData, { responseType: 'arraybuffer' });
}
const downloadLanguageProficiencytr = async (id, lang, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper()};
    if (note) {
        formData.note = note;
    }
    return Api.post('/student/documents/turkish-language-proficiency/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadEnglishPlacementExam = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/english-placement-exam/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadMilitaryStatus = async (id, lang, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper(), note };
    return Api.post('/student/documents/military-status/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadNewPassword = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/password/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadPrepTranscript = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/prep-transcript/' + id, formData, { responseType: 'arraybuffer' });
}

const downloadStudentCertificate = async (id, lang, note = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let data = {
        language: lang.toUpper()
    };
    if (note) {
        data.note = note;
    }
    return Api.post('/student/documents/student-certificate/' + id, data, { responseType: 'arraybuffer' });
}

const downloadTranscript = async (id, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/transcript/' + id, formData, { responseType: 'arraybuffer' })
}

const downloadWelcomeLetter = async (studentNumber, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/welcome/' + studentNumber, formData, { responseType: 'arraybuffer' });
}

const downloadGpaControl = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/documents/gpa-control/' + id);
}

const downloadDiploma = (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/diploma/' + id, formData, { responseType: 'arraybuffer' });
}
const downloadSecondDiploma = (id, ) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('student/documents/diploma-second-copy/' + id, null, { responseType: 'arraybuffer' });
}

const downloadDiplomaAnnexes = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/diploma-supplement/' + id, null, { responseType: 'arraybuffer' });
}

const downloadTemporaryGraduation = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/temporary-graduation-certificate/' + id, null, { responseType: 'arraybuffer' });
}

const downloadMinorCertificate = (id, formData = null, response_type = "arraybuffer") => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/minor-certificate/' + id, formData, { responseType: response_type });
}

const downloadAttendanceList = (section_ID,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/attendance-list/' + section_ID, data, { responseType: 'arraybuffer' });
}

const downloaWeeklydAttendanceList = (section_ID) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/documents/weekly-attendance-list/' + section_ID, { responseType: 'arraybuffer' });
}

const downloadExamReport = (exam_ID = '', classroom = '') => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/exam-report/' + exam_ID + '/' + classroom, null, { responseType: 'arraybuffer' });
}

const downloadExamListReport = (exam_ID) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/exam-report-list/' + exam_ID, null, { responseType: 'arraybuffer' });
}

const downloadRegistrationForm = (studentProgramId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/registration-form/' + studentProgramId, formData, { responseType: 'arraybuffer' });
}

const downloadGpaStudentExcel = (studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/documents/gpa-control-excel-export/' + studentProgramId, { responseType: 'arraybuffer' });
}

const downloadDisciplineRegistry = async (id, lang, note) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    let data = {
        language: lang.toUpper()
    };
    if (note) {
        data.note = note;
    }
    return Api.post('/student/documents/discipline/' + id, data, {
        responseType: 'arraybuffer'
    })
}

const downloadExamReportAllSection = (examId = '', classroom = '') => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/student/documents/all-section-exam-report/' + examId + '/' + classroom, null, { responseType: 'arraybuffer' })
}

const downloadDiplomaNotebook = (studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/diploma-notebook/' + studentProgramId, null, { responseType: 'arraybuffer' });
}
const LeavingReport = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/student/documents/leaving-report/" + id, formData, { responseType: 'arraybuffer' });
}

const alumniReport = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("student/documents/alumni-information-form/" + id, null, { responseType: 'arraybuffer' });
}

const downloadStudentCourseSchedule = (studentProgramId, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/course-schedule/' + studentProgramId, formData, { responseType: 'arraybuffer' });
}

const downloadStudentExamSchedule = (studentProgramId, lang) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let formData = { language: lang.toUpper() };
    return Api.post('/student/documents/exam-schedule/' + studentProgramId, formData, { responseType: 'arraybuffer' });
}
const twoCopiesWetSignature = (studentProgramId,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/diploma/' + studentProgramId,formData,{ responseType: 'arraybuffer' });
}
const educationInformationReporting = (studentProgramId,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/educations/excel-report',{ responseType: 'arraybuffer' });
}



export default {
    create,
    downloadCourseSchedule,
    downloadDispatchPostponement,
    downloadExamSchedule,
    downloadSchoolCertificate,
    downloadLanguageProficiency,
    downloadEnglishPlacementExam,
    downloadMilitaryStatus,
    downloadNewPassword,
    downloadPrepTranscript,
    downloadStudentCertificate,
    downloadTranscript,
    downloadWelcomeLetter,
    downloadGpaControl,
    downloadDiploma,
    downloadDiplomaAnnexes,
    downloadTemporaryGraduation,
    downloadMinorCertificate,
    downloadAttendanceList,
    downloadExamReport,
    downloadExamListReport,
    downloadRegistrationForm,
    downloadGpaStudentExcel,
    downloaWeeklydAttendanceList,
    downloadDisciplineRegistry,
    downloadExamReportAllSection,
    downloadDiplomaNotebook,
    LeavingReport,
    alumniReport,
    downloadSecondDiploma,
    downloadStudentCourseSchedule,
    downloadStudentExamSchedule,
    twoCopiesWetSignature,
    educationInformationReporting,
    downloadLanguageProficiencytr
}

